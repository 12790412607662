import React from 'react'
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

/**
 * Required modal props
 */
interface ModalProps {
  open: boolean
  toggleModal: any
  children: any
  handleSubmit: any
  styling: any
}

/**
 * Implements mui styling
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      margin: theme.spacing(3)
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }));

/**
 * General Modal window that can house any type of content 
 */
export default function ModalWindow(props: ModalProps) {
  const classes = useStyles();
  const { open, children, toggleModal, handleSubmit, styling } = props

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styling}
        open={open}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Paper>
            <Grid container>
              {children}
            </Grid>
            <Grid>
              <div className={classes.container} >
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}>
                  Close
                </Button>
              </div>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  )
}
