// expanding capabilities https://github.com/tannerlinsley/react-table/blob/master/examples/sub-components/src/App.js
// all other functionality https://github.com/tannerlinsley/react-table/tree/master/examples/material-UI-kitchen-sink

//#region Imports
import React from "react";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded,
} from "react-table";
import PaginationActions from "./components/PaginationActions";
import Toolbar from "./components/Toolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import GlobalFilter from "./components/GlobalFilter";
//#endregion

/**
 * The entity table displays all meta data for the histoic models
 * This component also houses the pagination, search and filter components
 * */
const DataTable = ({
  columns,
  data,
  tableState,
  renderRowSubComponent,
  tableRefresh,
}: {
  columns: any;
  data: any;
  tableState: any;
  renderRowSubComponent?: Function;
  tableRefresh?: Function;
}) => {
  //#region  Initialise the react table properties and hooks
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    allColumns,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !tableState.skipPageReset,
      // Initial state option for the table
      initialState: {
        hiddenColumns: tableState.filteredColumns,
        pageSize: tableState.rowsPerPageOptions.pageSize,
        sortBy: [
          {
            id: "modelId",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  //#endregion

  //#region Pagination handles
  const handleChangePage = (event: any, newPage: number) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(Number(event.target.value));
  };
  //#endregion

  return (
    <TableContainer>
      {/** The toolbar houses the search, column filter and refresh components */}
      <div style={{ display: "flex" }}>
        {tableState.globalSearchRequired && (
          <div>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        )}
        {tableState.toolbarRequired && (
          <Toolbar
            requiredColumns={tableState.requiredColumns}
            allColumns={allColumns}
            tableRefresh={tableRefresh}
          />
        )}
      </div>
      <Table size="small" {...getTableProps()}>
        {/** Column headers */}
        <TableHead>
          {headerGroups.map((headerGroup, i) => {
            return (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                key={`${headerGroup.id}_${i}`}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...(column.id === "selection"
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                      key={column.id}
                    >
                      {column.render("Header")}
                      {column.id !== "selection" ? (
                        <TableSortLabel
                          style={{ zIndex: -1 }} // Otherwise it shows over the top of dropdown menus
                          key={`lbl_${column.id}`}
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        {/** All table data */}
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              // Use a React.Fragment here so the table markup is still valid
              <React.Fragment key={`rf_${row.getRowProps().key}`}>
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {/*
                  If the row is in an expanded state, render a row with a
                  column that fills the entire length of the table.
                */}
                {renderRowSubComponent && row.isExpanded ? (
                  <TableRow key={`tr_${row.getRowProps().key}`}>
                    <TableCell
                      key={`tc_${row.getRowProps().key}`}
                      colSpan={visibleColumns.length}
                    >
                      {/*
                        Inside it, call our renderRowSubComponent function. In reality,
                        you could pass whatever you want as props to
                        a component like this, including the entire
                        table instance. But for this example, we'll just
                        pass the row
                      */}
                      {renderRowSubComponent({ row })}
                    </TableCell>
                  </TableRow>
                ) : null}
              </React.Fragment>
            );
          })}
        </TableBody>
        {/** The footer houses the pagination component */}
        <TableFooter>
          <TableRow>
            <TablePagination
              style={{ overflow: "hidden" }}
              rowsPerPageOptions={tableState.rowsPerPageOptions.pageSizes}
              colSpan={columns.length}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={PaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
