//#region Imports
import React, { useReducer, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ProvisionWarning from "./ProvisionWarning";
//#endregion

/** Implements material-ui styles */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    button: {
      margin: theme.spacing(2),
      padding: theme.spacing(1),
      width: 150,
    },
    section: {
      padding: theme.spacing(2),
    },
    input: {
      width: "100%",
      marginBottom: 7,
    },
    inputs: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      alignContent: "center",
      width: "85%",
    },
  })
);

enum ACTIONS {
  INIT,
  OPTIONS,
  INPUT,
  OUTPUT,
  TOGGLE_MODAL,
  BATCH_INPUTS,
  FAVOURITE,
}

const VmGpuCounts: any = {
  Standard_NC4as_T4_v3: [1],
  Standard_NC6s_v3: [1],
  Standard_NC12s_v3: [1, 2],
  Standard_NC24s_v3: [1, 2, 3, 4],
};

const defaultGridSizes: any = {
  Nerang: 15,
  Logan: 20,
  Coolangatta: 4,
  Coomera: 15,
  "Upper Coomera": 20,
  Tallebudgera: 10,
  Currumbin: 8,
  Loders: 5,
  Biggera: 10,
};

const defaultSimHours: any = {
  Nerang: 72,
  Logan: 72,
  Coolangatta: 24,
  Coomera: 50,
  "Upper Coomera": 50,
  Tallebudgera: 50,
  Currumbin: 50,
  Loders: 50,
  Biggera: 50,
}

function reducer(state: any, action: any) {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.OPTIONS:
      return {
        ...state,
        allOptions: payload,
      };
    case ACTIONS.OUTPUT:
      let new_value = { [payload.option]: payload.value };
      if (payload.option === "vmSize") {
        let available_gpus = VmGpuCounts[payload.value];
        return {
          ...state,
          inputs: {
            ...state.inputs,
            gpuCounts: available_gpus,
          },
          outputs: {
            ...state.outputs,
            ...new_value,
            gpuCount: 1,
          },
        };
      } else if (payload.option === "catchment") {
        let grid_size = defaultGridSizes[payload.value];
        let sim_hours = defaultSimHours[payload.value]
        return {
          ...state,
          outputs: {
            ...state.outputs,
            gridSize: grid_size,
            simulationHours: sim_hours,
            catchment: payload.value,
          },
        };
      } else {
        return {
          ...state,
          outputs: {
            ...state.outputs,
            ...new_value,
          },
        };
      }
    case ACTIONS.TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: payload,
      };
    default:
      return state;
  }
}

type VmInfo = {
  name: string;
  cost: number;
};

const initialState = {
  inputs: {
    automationLevels: ["Build Model VM and Run", "Build Model VM"],
    vmSizes: [
      { name: "Standard_NC4as_T4_v3", cost: 0.868 },
      { name: "Standard_NC6s_v3", cost: 2.142 },
      { name: "Standard_NC12s_v3", cost: 4.283 },
      { name: "Standard_NC24s_v3", cost: 8.566 },
    ],
    gpuCounts: [1],
    catchments: [
      "Nerang",
      "Logan",
      "Coolangatta",
      "Coomera",
      "Upper Coomera",
      "Tallebudgera",
      "Currumbin",
      "Loders",
      "Biggera",
    ],
    gridSizes: [4, 5, 8, 10, 15, 20],
    vmStateAfterModelComplete: ["Delete", "Deallocate", "Leave VM Running"],
  },
  outputs: {
    automationLevel: "Build Model VM and Run",
    vmSize: "Standard_NC4as_T4_v3",
    gpuCount: 1,
    catchment: "Nerang",
    simulationHours: 72,
    gridSize: 15,
    vmStateAfterModelComplete: "Delete",
  },
  modalOpen: false,
};

/**
 * This component collects the required data to provision a virtual machine in Azure
 * @returns The provisiong modal window
 */
export default function Provisioning() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const classes = useStyles();

  useEffect(() => {
    dispatch({ type: ACTIONS.INIT });
  }, [state]);

  const toggleModal = () => {
    dispatch({
      type: ACTIONS.TOGGLE_MODAL,
      payload: state.modalOpen === true ? false : true,
    });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.section}>
        <Typography variant="h4">VM Provisioning</Typography>
      </div>
      <div className={classes.inputs}>
        <TextField
          className={classes.input}
          id={"automationLevel"}
          select
          variant={"outlined" as any}
          label={"Startup Type"}
          value={state.outputs.automationLevel}
          onChange={(e) =>
            dispatch({
              type: ACTIONS.OUTPUT,
              payload: { option: "automationLevel", value: e.target.value },
            })
          }
        >
          {state.inputs.automationLevels.map((selection: string) => {
            return (
              <MenuItem key={selection as string} value={selection as string}>
                {selection}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          className={classes.input}
          id={"vmSize"}
          select
          variant={"outlined" as any}
          label={"VM Size"}
          value={state.outputs.vmSize}
          onChange={(e) =>
            dispatch({
              type: ACTIONS.OUTPUT,
              payload: { option: "vmSize", value: e.target.value },
            })
          }
        >
          {state.inputs.vmSizes.map((selection: VmInfo) => {
            return (
              <MenuItem
                key={selection.name as string}
                value={selection.name as string}
              >
                {selection.name} at ${selection.cost}/hr
              </MenuItem>
            );
          })}
        </TextField>
        {state.outputs.automationLevel === "Build Model VM and Run" && (
          <div>
            <TextField
              className={classes.input}
              id={"gpuCount"}
              select
              variant={"outlined" as any}
              label={"GPU Count"}
              value={state.outputs.gpuCount}
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.OUTPUT,
                  payload: { option: "gpuCount", value: e.target.value },
                })
              }
            >
              {state.inputs.gpuCounts.map((selection: string) => {
                return (
                  <MenuItem
                    key={selection as string}
                    value={selection as string}
                  >
                    {selection}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              className={classes.input}
              id={"catchment"}
              select
              variant={"outlined" as any}
              label={"Catchment"}
              value={state.outputs.catchment}
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.OUTPUT,
                  payload: {
                    option: "catchment",
                    value: e.target.value,
                  },
                })
              }
            >
              {state.inputs.catchments.map((selection: string) => {
                return (
                  <MenuItem
                    key={selection as string}
                    value={selection as string}
                  >
                    {selection}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              id={"simulationHours"}
              variant={"outlined" as any}
              label={"Simulation Hours"}
              value={state.outputs.simulationHours}
              className={classes.input}
              type="number"
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.OUTPUT,
                  payload: { option: "simulationHours", value: e.target.value },
                })
              }
            />
            <TextField
              id={"gridSize"}
              variant={"outlined" as any}
              label={"Grid Size"}
              value={state.outputs.gridSize}
              className={classes.input}
              select
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.OUTPUT,
                  payload: { option: "gridSize", value: e.target.value },
                })
              }
            >
              {state.inputs.gridSizes.map((size: string) => {
                return (
                  <MenuItem key={size as string} value={size as string}>
                    {size}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              className={classes.input}
              id={"vmStateAfterModelComplete"}
              select
              variant={"outlined" as any}
              label={"On Completion"}
              value={state.outputs.vmStateAfterModelComplete}
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.OUTPUT,
                  payload: {
                    option: "vmStateAfterModelComplete",
                    value: e.target.value,
                  },
                })
              }
            >
              {state.inputs.vmStateAfterModelComplete.map(
                (selection: string) => {
                  return (
                    <MenuItem
                      key={selection as string}
                      value={selection as string}
                    >
                      {selection}
                    </MenuItem>
                  );
                }
              )}
            </TextField>
            <TextField
              id={"csvHistoryModelId"}
              variant={"outlined" as any}
              label={"CSV History Model ID"}
              value={state.outputs.csvHistoryModelId}
              className={classes.input}
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.OUTPUT,
                  payload: { option: "csvHistoryModelId", value: e.target.value },
                })
              }
            />
          </div>
        )}
      </div>
      <div>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => toggleModal()}
        >
          Provision
        </Button>
      </div>
      <ProvisionWarning
        isOpen={state.modalOpen}
        data={state.outputs}
        toggleModal={toggleModal}
      />
    </Paper>
  );
}
