import { useState, useEffect } from 'react'
import { useAxiosGet } from './useAxios'

/**
 * Hook to request a list of user emails
 * @returns All email data, loading state, any errors and the ability to recall the API
 */
export function useGetEmails(): { emailData: Array<{ email: string }>, rehydrate: Function, isLoading: boolean, error: string | null } {
  const key = process.env.REACT_APP_FUNCTION_KEY
  const env = process.env.REACT_APP_ENVIRONMENT
  const func_name = env == "Prod" ? "cogcfedss" : "cogctfedss"
  const url = `https://${func_name}.azurewebsites.net/api/email_query?code=${key}`

  const [emailData, setEmailData] = useState<Array<{ email: string }>>([])
  const { data, isLoading, recall, error } = useAxiosGet(url)

  useEffect(() => {
    setEmailData(data)
  }, [data])

  const rehydrate = () => {
    recall()
  }

  return { emailData: emailData, rehydrate: rehydrate, isLoading: isLoading, error: error }
}