import axios from 'axios'

/**
 * API request that begins the provisioning of an Azure Virtual Machine
 * @param body Content required to provision a virtual machine
 * @returns Status code of the API request
 */
export async function provisionVM(body: string): Promise<number> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: process.env.REACT_APP_VM_PROVISION_WEBHOOK,
      headers: { },
      data: body
    })
      .then(resp => resolve(resp.status))
      .catch(console.error)
  })
}