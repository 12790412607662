//#region Imports
import { useCallback, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Actions, Action } from "./index.t";
import IconButton from "@material-ui/core/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import StopIcon from "@mui/icons-material/Stop";
import FloodAnimation from "./classes/FloodAnimation";
import { OverlayFrame, } from "../../components/mapping/Shared";
import styled from "styled-components";
import FloodExtent from "./classes/FloodExtent";
import FloodedProperties from "./classes/FloodedProperties";
import CutRoads from "./classes/CutRoads";
import RoadClosures from "./classes/RoadClosures";
import USGSOverlay from "../../components/mapping/USGSOverlay";
//#endregion

//#region Styled components
const Container = styled("div")((props) => ({
  display: "flex",
  width: 'inherit'
}));

const MapContainer = styled("div")((props) => ({
  width: "100%",
}));

const CatchmentHeading = styled("label")((props) => ({
  fontWeight: 500,
  fontSize: 24
}));

const StyledLabel = styled("label")((props) => ({
  fontWeight: 400,
  fontSize: 16,
  margin: 5,
}));

const GridColumn = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  padding: 10
}));

const GridRow = styled("div")((props) => ({
  display: "flex",
  width: '100%'
}));

const Card = styled("div")((props) => ({
  width: '100%',
  minWidth: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: 'center',
  border: '1px solid lightgray',
  borderRadius: '5px',
  boxShadow: '4px 4px 10px lightgray',
  margin: "10px 10px 0 10px",
  padding: 10
}));

const DownloadButton = styled("button")((props) => ({
  fontSize: 16,
  cursor: "pointer",
  padding: 3,
  borderRadius: 5,
  margin: 3,
  border: '1px solid black',
  '*&:hover': {
    backgroundColor: 'lightgray'
  }
}));

const SelectedCheckbox = styled("input")((props) => ({
  transform: `scale(1.7)`,
  margin: 10,
  '&:hover': {
    cursor: 'pointer'
  }
}));
//#endregion

//#region State management
// Enum list for all process types
enum LayerType {
  floodAnimation = "floodAnimation",
  floodExtent = "floodExtent",
  roadClosures = "roadClosures",
  cutRoads = "cutRoads",
  floodedProperties = "floodedProperties"
}

/**
 * The reducer used to manage the change of state
 * @param state The current state of the page
 * @param action Action to be carried out
 * @returns The updated state
 */
function reducer(state: any, action: Action) {
  const { payload } = action;
  switch (action.type) {
    case Actions.INIT_MAP:
      var floodAnimation = payload.layers.floodAnimation as FloodAnimation;
      var floodExtent = payload.layers.floodExtent as FloodExtent;
      // Loops over the flood animation frames, applies them to the map and then hides the div from view
      floodAnimation.animationFrames = floodAnimation.animationFrames.map((frame: OverlayFrame, index: number) => {
        frame.overlay.setMap(payload.map)
        frame.overlay.zIndex = 1
        index !== 0 && frame.overlay.hide()
        return frame
      })
      // Sets the flood extent frame and then hides it from view
      if (floodExtent.extentFrame) {
        floodExtent.extentFrame.overlay.zIndex = -1
        floodExtent.extentFrame.overlay.setMap(payload.map)
        floodExtent.extentFrame.overlay.hide()
      }
      return {
        ...state,
        ...payload,
        layers: { ...payload.layers, floodAnimation: floodAnimation },
        currentFrame: floodAnimation.animationFrames[0], // Sets the current frame as the first frame from the list
      };

    case Actions.MAP_LOADED:
      // Used to know when to display the map
      return {
        ...state,
        mapLoaded: payload
      };
    // Used to change the animation frame for the next/prev buttons
    case Actions.SELECT_ANIMATION_FRAME:
      let length = state.layers.floodAnimation.animationFrames.length
      let position = payload > length - 1 ? 0 : payload < 0 ? length - 1 : payload
      let frame = state.layers.floodAnimation.animationFrames[position]
      state.layers.floodAnimation.animationPosition = position
      state.currentFrame.overlay.hide()
      frame.overlay.show()
      return {
        ...state,
        currentFrame: frame
      };
    // Toggles the visiblity of the selected layer on the map
    case Actions.TOGGLE_LAYER:
      if (payload.type === LayerType.floodAnimation) {
        state.currentFrame.overlay.toggle()
        console.log(state.currentFrame.overlay);
      } else if (payload.type === LayerType.floodExtent) {
        let overlay = state.layers.floodExtent.extentFrame.overlay as USGSOverlay
        if (overlay.getMap()) {
          payload.value ? overlay.show() : overlay.hide()
        } else {
          overlay.setMap(state.map)
        }
      } else {
        let layer = state.layers[payload.type]
        payload.value ? layer.show() : layer.hide()
      }

      return {
        ...state,
        selectedLayers: {
          ...state.selectedLayers,
          [payload.type]: payload.value
        }
      }
    // Start/stop the flood animation
    case Actions.TOGGLE_ANIMATION:
      return {
        ...state,
        animationPlaying: !state.animationPlaying
      }
    // Handles how the slider selects the animation frame
    case Actions.SELECTED_FRAME:
      let selected = state.layers.floodAnimation.animationFrames[payload] as OverlayFrame
      state.layers.floodAnimation.animationPosition = payload
      state.currentFrame.overlay.hide()
      selected.overlay.show()
      return {
        ...state,
        currentFrame: selected
      }
  }
}

// Initial state of the page
const initialState = {
  mapLoaded: false,
  animationPlaying: false,
  layers: {},
  selectedLayers: {
    floodAnimation: true,
    floodExtent: false,
    cutRoads: false,
    floodedRoads: false,
    floodedProperties: false
  }
};
//#endregion

export function Overlays() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const params = useParams();

  useEffect(() => {
    const { catchment, model_id, start_time, start_date } = params;
    if (catchment && model_id && start_time && start_date) {
      // Create the google map object
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 11,
          center: { lat: -27.989, lng: 153.336 },
          mapTypeId: "satellite",
        }
      );
      // Using the URL params prepare all map layers
      var flood_animation = new FloodAnimation(catchment!, model_id!, start_time!, start_date!);
      // The first thing to do is generate the animation frames to be added to state
      flood_animation
        .generateOverlays()
        .then((overlayFrames: Array<OverlayFrame>) => {
          flood_animation.animationFrames = overlayFrames;
          const flood_extent = new FloodExtent(catchment!, model_id!)
          flood_extent.generateOverlay()
          dispatch({
            type: Actions.INIT_MAP,
            payload: {
              map: map,
              layers: {
                floodAnimation: flood_animation,
                floodExtent: flood_extent,
                floodedProperties: new FloodedProperties(catchment!, model_id!, map),
                cutRoads: new CutRoads(catchment!, model_id!, map),
                roadClosures: new RoadClosures(catchment!, model_id!, map)
              }
            },
          });
        });
    }
  }, [params]);

  useEffect(() => {
    // Once the map is set, create any additional elements and apply map bounds and zoom
    if (state.map) {
      state.currentFrame.overlay.setMap(state.map)

      // Address Search
      var input_field = document.getElementById(
        "pac-input"
      ) as HTMLInputElement;

      var auto_complete = new google.maps.places.Autocomplete(
        input_field,
        {
          bounds: state.currentFrame.mapBounds,
          strictBounds: true,
        }
      );
      auto_complete.bindTo("bounds", state.map);

      var marker = new google.maps.Marker({
        map: state.map,
        anchorPoint: new google.maps.Point(0, -29),
      });

      auto_complete.addListener("place_changed", function () {
        marker.setVisible(false);
        var place = auto_complete.getPlace();
        if (!place.geometry) {
          console.log("No geometry found for place");
          return;
        }

        if (place.geometry.viewport) {
          state.map.fitBounds(place.geometry.viewport);
        } else {
          state.map.setCenter(place.geometry.location!);
          state.map.setZoom(14);
        }

        marker.setIcon({
          url: place.icon!,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(35, 35),
        });
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
      });

      dispatch({
        type: Actions.MAP_LOADED,
        payload: true,
      });
    }
  }, [state.map, state.currentFrame])

  useEffect(() => {
    // When the maps is loaded fit the view to the current layer
    if (state.mapLoaded) {
      state.map.fitBounds(state.layers.floodAnimation.animationFrames[0].mapBounds);
    }
  }, [state.map, state.mapLoaded, state.layers])

  /**
   * Callback for moving to the next animation frame
   */
  const handleNextFrame = useCallback(
    () => {
      if (state.animationPlaying) {
        dispatch({ type: Actions.TOGGLE_ANIMATION })
      } else {
        dispatch({ type: Actions.SELECT_ANIMATION_FRAME, payload: state.currentFrame.position + 1 })
      }
    },
    [state.currentFrame],
  )

  useEffect(() => {
    // Process to create the flood animation
    if (state.animationPlaying) {
      setTimeout(() => dispatch({ type: Actions.SELECT_ANIMATION_FRAME, payload: state.currentFrame.position + 1 }), 1000);
    }
  }, [state.animationPlaying, handleNextFrame]);

  /**
   * Moves the animation to the previous frame
   */
  function handlePreviousFrame() {
    if (state.animationPlaying) {
      dispatch({ type: Actions.TOGGLE_ANIMATION })
    } else {
      dispatch({ type: Actions.SELECT_ANIMATION_FRAME, payload: state.currentFrame.position - 1 })
    }
  }

  /**
   * Handle the toggling of the layers
   * @param type The layer type to toggle
   * @param selected Is the layer selected
   */
  function handleLayersChange(type: LayerType, selected: boolean) {
    dispatch({
      type: Actions.TOGGLE_LAYER,
      payload: {
        type: type,
        value: selected
      },
    });
  }

  /**
   * Creates an anchor element to download a file
   * @param endpoint The endpoint of the file to be downloaded
   */
  const handleDownload = (endpoint: string) => {
    const link = document.createElement("a");
    link.href = endpoint;
    link.click();
  };

  /**
   * Converts the first letter of each word in the catchment to upper case
   * @param name The name of the catchment
   * @returns Formatted catchment name
   */
  function catchmentToUpper(name: string) {
    return name
      .split('_')
      .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ')
  }

  /**
   * Manages what frame is selected from the movement of the slider
  */
  function handleSliderChange(value: string) {
    dispatch({
      type: Actions.SELECTED_FRAME,
      payload: parseInt(value)
    })
  }

  return (
    <Container>
      <GridRow>
        <MapContainer>
          <div
            id="map"
            style={{
              width: "100%",
              height: "calc(90vh - 5%)",
              display: state.mapLoaded ? "flex" : "none",
            }}
          ></div>

        </MapContainer>
        <GridColumn>
          <input
            id="pac-input"
            type="text"
            placeholder="Address Search"
            style={{
              width: "100%",
              margin: "0 10px 10px 10px",
              fontSize: 14,
              padding: 5,
              borderRadius: 5,
            }}
          />
          <Card>
            {/* Catchment and flood animation frame information */}
            {params.catchment && <CatchmentHeading>{catchmentToUpper(params.catchment)}</CatchmentHeading>}
            {state.currentFrame &&
              <GridColumn>
                <StyledLabel>Start: {state.currentFrame.start}</StyledLabel>
                <StyledLabel>End: {state.currentFrame.end}</StyledLabel>
                <StyledLabel>SimHours: {state.layers.floodAnimation.animationFrames.length}</StyledLabel>
              </GridColumn>
            }
          </Card>
          <Card>
            {/* Flood Animation slider */}
            <StyledLabel>
              {state.currentFrame && state.currentFrame.start}
            </StyledLabel>
            {state.layers.floodAnimation && (
              <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: '100%'
              }}>
                <input
                  style={{ width: "100%" }}
                  type="range"
                  min={0}
                  max={state.layers.floodAnimation.animationFrames.length - 1}
                  step={1}
                  onChange={(e) => handleSliderChange(e.target.value)}
                  value={state.layers.floodAnimation.animationPosition}
                />

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <IconButton
                    aria-label="previous"
                    onClick={handlePreviousFrame}
                  >
                    <SkipPreviousIcon />
                  </IconButton>
                  <IconButton
                    aria-label="play/pause"
                    onClick={() => dispatch({ type: Actions.TOGGLE_ANIMATION })}
                  >
                    {state.animationPlaying ? (
                      <StopIcon />
                    ) : (
                      <PlayArrowIcon />
                    )}
                  </IconButton>
                  <IconButton aria-label="next" onClick={handleNextFrame}>
                    <SkipNextIcon />
                  </IconButton>
                </div>
              </div>
            )}
          </Card>
          <Card>
            {/* Tabs for post processing options */}
            <Tabs style={{ width: "100%" }}>
              <TabList>
                <Tab>
                  Layers
                </Tab>
                <Tab>
                  KMZ Downloads
                </Tab>
              </TabList>
              <TabPanel>
                <GridColumn>
                  {/* Layer selection check boxes */}
                  <div style={{ textAlign: "left" }}>
                    {state.layers && (
                      <div>
                        {Object.keys(state.layers).map((layer: any) => {
                          return <div key={layer} style={{ display: "flex", alignItems: 'center' }}>
                            <SelectedCheckbox
                              type={"checkbox"}
                              checked={state.selectedLayers[layer] || false}
                              onChange={(e) =>
                                handleLayersChange(layer, e.target.checked)
                              }
                            />
                            <label style={{ fontSize: 16, paddingLeft: 10 }}>{state.layers[layer].label}</label>
                          </div>
                        })}
                      </div>
                    )}
                  </div>
                </GridColumn>
              </TabPanel>
              <TabPanel>
                <GridColumn>
                  <GridRow style={{ display: "flex", flexDirection: "column" }}>
                    {/* Layer kml download buttons  */}
                    {state.layers &&
                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        {Object.keys(state.layers).map((layer: any) => {
                          return (<DownloadButton
                            key={state.layers[layer].id}
                            onClick={() => handleDownload(state.layers[layer].kmzEndpoint)}
                          >
                            {state.layers[layer].label}
                          </DownloadButton>
                          )
                        })}
                      </div>
                    }
                  </GridRow>
                </GridColumn>
              </TabPanel>
            </Tabs>
          </Card>
        </GridColumn>
      </GridRow >
    </Container >
  );
}

export default Overlays;
