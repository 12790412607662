//#region Imports
import React from 'react'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { makeStyles, useTheme } from '@material-ui/core/styles'
//#endregion

/** Implements material-ui styling */
const useStyles = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
        width: "100%"
    },
}))

/** Handles the movement between the pages of the table */
export default function PaginationActions(props: any) {
    const classes = useStyles()
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    //#region Handles for pagination buttons
    /** Returns the table back to the first page */
    const handleFirstPageButtonClick = (event: any) => {
        onPageChange(event, 0)
    }

    /** Previous page */
    const handleBackButtonClick = (event: any) => {
        onPageChange(event, page - 1)
    }

    /** Next page */
    const handleNextButtonClick = (event: any) => {
        onPageChange(event, page + 1)
    }

    /** Changes the table to show the final page */
    const handleLastPageButtonClick = (event: any) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }
    //#endregion

    return (
        <div className={classes.root}>
            {/** All four pagination buttons */}
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}