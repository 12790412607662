import React from "react";
import App from "./App";
import "react-tabs/style/react-tabs.css";
import { Account } from "msal";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { MSALAuthConfig } from "./components/authentication/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

export interface AuthContent {
  accountInfo: {
    account: Account;
    jwtIdToken: string;
  };
  authenticationState: string;
  error: string | undefined;
  login: Function;
  logout: Function;
}

/** MSAL auth config */
const msal_auth = new MSALAuthConfig();
export const msalInstance = new PublicClientApplication(msal_auth.msal_config);
/** Call back for when an msal instance creates an event */
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

/** React 18 way of creating the root element */
const container = document.getElementById("root");
const root = createRoot(container!);

/** Azure AD wraps the entire app so no page can be visited unless you meet the requirements */
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter basename="/fedss">
      <App msal_auth={msal_auth} />
    </BrowserRouter>
  </MsalProvider>
);
