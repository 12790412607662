import { ProcessTypes } from '../../../components/mapping/LayerTypes.t'

/**
 * A class for the Road Closures post processing results
 */
export default class RoadClosures {
  id: string
  baseEndpoint: string
  kmlEndpoint: string
  kmzEndpoint: string
  modelId: string
  catchment: string
  label: string
  sasKey: string | undefined;
  isChecked: boolean = false
  layer: google.maps.KmlLayer | undefined
  infoWindow: google.maps.InfoWindow | undefined
  map: google.maps.Map

  constructor(catchment: string, modelId: string, map: google.maps.Map) {
    this.id = ProcessTypes.ROAD_CLOSURES
    this.catchment = catchment
    this.modelId = modelId
    this.sasKey = process.env.REACT_APP_SAS_KEY;
    this.map = map
    this.label = 'Road Closures'
    this.baseEndpoint = process.env.REACT_APP_MODEL_RESULTS_ENDPOINT!;
    this.kmzEndpoint = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/road_closures/road_closures.kmz?${this.sasKey}`
    this.kmlEndpoint = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/road_closures/road_closures.kml?${this.sasKey}`
  }

  /**
   * Creates a KML layer from the KML file that be applied directly to the map
   */
   setLayer() {
    this.layer = new google.maps.KmlLayer({
      map: this.map,
      url: this.kmzEndpoint,
      suppressInfoWindows: false,
      preserveViewport: true
    })
  }

  /**
   * Sets the later to the map
   */
  show() {
    if (this.layer) {
      this.layer.setMap(this.map)
    } else {
      this.setLayer()
    }
  }

  /**
   * Removes the layer from the map
   */
  hide() {
    if (this.layer) {
      this.layer.setMap(null)
    }
  }
}