//#region Imports
import React, { useState, useEffect } from "react";
import logo from "../../images/logos/logo-cogc.png";
import { Link } from "react-router-dom";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FeedbackIcon from '@mui/icons-material/Feedback';
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import HelpIcon from "@mui/icons-material/Help";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import ExploreIcon from "@mui/icons-material/Explore";
import { msalInstance } from "../../index";
//#endregion

/** Default width of the side navigation bar */
const drawerWidth = 240;

/** Implements material-ui stlyes */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    textAlign: "right",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

/** The main navigation bar and the controls for the permanent side nav */
export default function Index(props: any) {
  const classes = useStyles();
  const { children } = props;
  const theme = useTheme();
  /** Store the logged in users id to show in the nav bar */
  const [userName, setUserName] = useState("");
  const [open, setOpen] = React.useState(false);

  /** Set the username */
  useEffect(() => {
    const account = msalInstance.getActiveAccount();
    if (account) {
      setUserName(account.username);
    }
  }, [props]);

  //#region Side nave open/close handles
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  //#endregion

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/** The bar that stretches across the top of the page */}
      <AppBar
        color="secondary"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <img
              style={{ height: "5vh" }}
              alt="City of Gold Coast"
              src={logo}
            />
          </Link>
          <Typography
            variant="subtitle1"
            color={"textPrimary"}
            className={classes.title}
          >
            {userName}
          </Typography>
        </Toolbar>
      </AppBar>
      {/** Side navigation pane and the links to each component of the site */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link color="inherit" to="/">
            <ListItem button key={"modelling"}>
              <ListItemIcon>
                <ExploreIcon />
              </ListItemIcon>
              <ListItemText primary={"Latest"} />
            </ListItem>
          </Link>
          <Link color="inherit" to="/modelling">
            <ListItem button key={"modelling"}>
              <ListItemIcon>
                <AddToQueueIcon />
              </ListItemIcon>
              <ListItemText primary={"Modelling"} />
            </ListItem>
          </Link>
          <Link color="inherit" to="/history">
            <ListItem button key={"history"}>
              <ListItemIcon>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary={"History"} />
            </ListItem>
          </Link>
          <Link color="inherit" to="/global-config">
            <ListItem button key={"global-config"}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Global Config"} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link color="inherit" to="/help">
            <ListItem button key={"help"}>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary={"Help"} />
            </ListItem>
          </Link>
          <a color="inherit" href="https://forms.office.com/r/rn4aCVrJd5" target="_blank" >
            <ListItem button key={"feedback"}>
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText primary={"Feedback"} />
            </ListItem>
          </a>
        </List>
      </Drawer>
      {/** The navbar component wraps every child component so it does not refresh
       * when navigating to each compoent */}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}
