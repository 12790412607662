import USGSOverlay from "./USGSOverlay"

/**
 * Interface for an image extent
 */
interface OverlayExtenatCoords {
  swLat: number
  swLng: number
  neLat: number
  neLng: number
}

/**
 * Interface for an image overlay frame
 */
export interface OverlayFrame {
  name: string,
  start?: string,
  end?: string,
  url: string,
  position?: number,
  overlay: USGSOverlay,
  mapBounds: google.maps.LatLngBounds
}

/**
 * Converts the overlay extent coords from a string to an object
 * @param latlonQuadCoordinates KML string representation of the overlay extent coords
 * @returns The bounds of an image overlay
 */
export function overlayExtent(latlonQuadCoordinates: string): OverlayExtenatCoords {
  let points = latlonQuadCoordinates.split(' ')
  let sw_strings = points[0].split(',')
  let ne_strings = points[2].split(',')

  /** Used to set the bounds for the image */
  return {
    swLat: parseFloat(sw_strings[1]),
    swLng: parseFloat(sw_strings[0]),
    neLat: parseFloat(ne_strings[1]),
    neLng: parseFloat(ne_strings[0])
  }
}