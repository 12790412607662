import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";

interface Entry {
  ["Catchment"]: string;
  ["Model ID"]: string;
  ["GPU Count"]: number;
  ["Grid Size"]: number;
  ["Initiated By"]: string;
  ["Model Finish"]: string;
  ["Model Runtime"]: string;
  ["Model Start"]: string;
  ["Simulation Hours"]: string;
  ["Storage State"]: string;
  ["VM Size"]: string;
  ["YearMonth"]: string;
  ["URBS Start Date"]: string;
  ["URBS Start Time"]: string;
  ["URBS FORE"]: string;
  ["URBS Probability"]: number;
  ["Deploy Start"]: string;
}

/**
 * The entity class represents a record for each model completed and all meta data
 * related to that model
 */
export default class Entity {
  id: string;
  catchment: string;
  modelId: string;
  gpuCount: number;
  modelRuntime: string = "00:00:00";
  vmSize: string;
  simulationHours: number;
  gridSize: number;
  modelStart: DateTime | string = "";
  modelStartDate: string | undefined = "";
  modelStartTime: string = "";
  modelFinish: DateTime | string = "";
  modelFinishDate: string | undefined = "";
  modelFinishTime: string = "";
  initiatedBy: string;
  yearMonth: string;
  storageState: string;
  urbsStartDate: string | undefined;
  urbsStartTime: string;
  urbsFore: string;
  urbsProbability: number;
  deployStart: DateTime | string = "";
  deployStartDate: string | undefined = "";
  deployStartTime: string = "";

  constructor(record: Entry) {
    //#region Model Start and End Datetimes
    if (record["Deploy Start"]) {

      let start = record["Deploy Start"].replaceAll("-", "/").split(" ")
      let split_start_date = start[0].split("/");
      let split_start_time = start[1].split(":");
      let start_merdiem = start[2];
      let start_hour = parseInt(split_start_time[0]);

      let deploy_start = DateTime.fromObject({
        year: 2000 + parseInt(split_start_date[2]),
        month: parseInt(split_start_date[1]),
        day: parseInt(split_start_date[0]),
        hour:
          start_merdiem === "PM" && start_hour > 12
            ? start_hour + 12
            : start_hour,
        minute: parseInt(split_start_time[1]),
      });

      this.deployStart = deploy_start;

      this.deployStartDate = this.prepareDate(this.deployStart.toISODate());
      this.deployStartTime = `${this.deployStart.toFormat(
        "T"
      )} ${start_merdiem}`;
    } else {
      this.deployStart = "00-00-0000 00:00";
      this.deployStartDate = "00-00-0000";
      this.deployStartTime = "00:00";
    }


    if (record["Model Start"]) {
      let start = record["Model Start"].split(" ");
      let split_start_date = start[0].split("/");
      let split_start_time = start[1].split(":");
      let start_merdiem = start[2];
      let start_hour = parseInt(split_start_time[0]);

      let model_start = DateTime.fromObject({
        year: 2000 + parseInt(split_start_date[2]),
        month: parseInt(split_start_date[1]),
        day: parseInt(split_start_date[0]),
        hour:
          start_merdiem === "PM" && start_hour > 12
            ? start_hour + 12
            : start_hour,
        minute: parseInt(split_start_time[1]),
      });

      this.modelStart = model_start;
      this.modelStartDate = this.prepareDate(this.modelStart.toISODate());
      this.modelStartTime = `${this.modelStart.toFormat("T")} ${start_merdiem}`;
    } else {
      this.modelStart = "00-00-0000 00:00";
      this.modelStartDate = "00-00-0000";
      this.modelStartTime = "00:00";
    }

    if (record["Model Finish"] != undefined) {
      let finish = record["Model Finish"].split(" ");
      let split_finish_date = finish[0].split("/");
      let split_finish_time = finish[1].split(":");
      let finish_hour = parseInt(split_finish_time[0]);
      let finish_merdiem = finish[2];

      let model_finish = DateTime.fromObject({
        year: 2000 + parseInt(split_finish_date[2]),
        month: parseInt(split_finish_date[1]),
        day: parseInt(split_finish_date[0]),
        hour:
          finish_merdiem === "PM" && finish_hour > 12
            ? finish_hour + 12
            : finish_hour,
        minute: parseInt(split_finish_time[1]),
      });

      this.modelFinish = model_finish;
      this.modelFinishDate = this.prepareDate(this.modelFinish.toISODate());
      this.modelFinishTime = `${this.modelFinish.toFormat(
        "T"
      )} ${finish_merdiem}`;
    } else {
      this.modelFinish = "00-00-0000 00:00";
      this.modelFinishDate = "00-00-0000";
      this.modelFinishTime = "00:00";
    }
    //#endregion

    //#region URBS Start Datetime
    if (record["URBS Start Date"] && record["URBS Start Time"]) {
      let urbs_date = record["URBS Start Date"].split("-");
      let urbs_time = record["URBS Start Time"].split(":");

      let date_time = DateTime.fromObject({
        year: parseInt(urbs_date[2]),
        month: parseInt(urbs_date[1]),
        day: parseInt(urbs_date[0]),
        hour: parseInt(urbs_time[0]),
        minute: parseInt(urbs_time[1]),
      });

      this.urbsStartDate = this.prepareDate(date_time.toISODate());
      this.urbsStartTime = date_time.toFormat("T");
    } else {
      this.urbsStartDate = "00-00-0000";
      this.urbsStartTime = "00:00";
    }

    //#endregion

    this.id = uuidv4();
    this.catchment = record.Catchment;
    this.modelId = record["Model ID"];
    this.gpuCount = record["GPU Count"];
    this.gridSize = record["Grid Size"];
    this.initiatedBy = record["Initiated By"].toLowerCase();

    if (record["Model Runtime"]) {
      this.modelRuntime = record["Model Runtime"]
        .split(":")
        .map((part: string) => {
          return part.length === 1 ? `0${part}` : part;
        })
        .join(":");
    }

    this.simulationHours = parseInt(record["Simulation Hours"]);
    this.storageState = record["Storage State"];
    this.vmSize = record["VM Size"];
    this.yearMonth = record["YearMonth"];
    this.urbsFore = record["URBS FORE"];
    this.urbsProbability = record["URBS Probability"];
  }

  prepareDate(ISODate: string) {
    if (ISODate) {
      return ISODate.split("-").reverse().join("-");

    }
  }
}
