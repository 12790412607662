import axios from 'axios'
import { VirtualMachine, VirtualMachines } from '../../pages/modelling/classes/VirtualMachine'

/**
 * Lists all modelling virtual machines so they can be displayed in a table for monitoring
 * @returns A list of modelling virtual machines
 */
export async function listVirtualMachines(): Promise<VirtualMachines> {
  const env = process.env.REACT_APP_ENVIRONMENT
  const key = process.env.REACT_APP_FUNCTION_KEY
  const func_name = env == "Prod" ? "cogcfedss" : "cogctfedss"

  let payload = JSON.stringify({ deployEnvironment: env })
  let url = `https://${func_name}.azurewebsites.net/api/vm_list?payload=${payload}&code=${key}`
  // let url = `https://cogcfedss.azurewebsites.net/api/vm_list?payload=${payload}&code=LAM8Z0P7Lh8UtKkjFwtdamLNNNWdmU7azGg3F0KdGHZsDvqCsIsYpg==`

  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: url,
      headers: {}
    })
      .then(resp => {
        if (resp.data.length != 0) {
          let allMachines: VirtualMachines = new VirtualMachines()
          resp.data.records.forEach((vm: any) => allMachines.addVirtualMachine(
            new VirtualMachine(
              vm.Name,
              vm.Size,
              vm.State,
              vm.IP,
              vm['Initiated By'],
              vm.Days,
              vm.Timestamp
            )
          ))
          resolve(allMachines)
        }
      })
      .catch(err => reject(err))
  })
}

/**
 * Sends a POST request to change the state of the machine
 * @param state The state that the machine should be set to
 * @param vmName The name of the virtual machine 
 * @param initiatedBy The email of the user that initiated this action
 * @returns The response from the post reuques
 */
export async function changeVirtualMachineState(state: string, vmName: string, initiatedBy: string): Promise<any> {
  let webhook = 'https://a077adde-47f8-499d-96dc-204851a5f323.webhook.ae.azure-automation.net/webhooks?token=xVpsgt86DH5wpvxNvIIPx2u%2focVR108WhI9aSE0pOMw%3d'
  let payload = {
    report: 'FEDSS-VMManage',
    parameters: {
      vmName: vmName,
      action: state,
      initiatedBy: initiatedBy,
      emailNotificationList: initiatedBy
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: webhook,
      headers: {},
      data: JSON.stringify(payload)
    })
      .then(resp => resolve(resp))
      .catch(err => reject(err))
  })
}