import React, { useEffect, useMemo, useReducer, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DataTable from "../../../components/data-tables/DataTable";
import { provisionVM } from "../../../components/api-requests/provisionRequests";
import { Row, Column } from "react-table";
import { useGetEmails } from "../../../components/hooks/useEmailRecipients";
import { msalInstance } from "../../../index";
import { AccountInfo } from "@azure/msal-browser";

/** implements material-ui stlying */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
      width: 175,
    },
    container: {
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "space-evenly",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      borderRadius: 5,
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2, 4, 3),
    },
    header: {
      textAlign: "center",
      margin: theme.spacing(1),
    },
    outputs: {
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
    },
    inputData: {
      margin: "0 30px",
      width: 350,
    },
    subHeading: {
      textAlign: "center",
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
    },
    emailList: {
      overflow: "auto",
      height: 400,
    },
    listButton: {
      width: 100,
    },
  })
);

enum ACTION {
  ADD_EMAIL,
  REMOVE_EMAIL,
  PREPARE_METADATA,
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case ACTION.ADD_EMAIL:
      return {
        ...state,
        emailNotificationList: [
          ...state.emailNotificationList,
          action.payload.email,
        ],
      };
    case ACTION.REMOVE_EMAIL:
      return {
        ...state,
        emailNotificationList: state.emailNotificationList.filter(
          (email: string) => email !== action.payload.email
        ),
      };
    case ACTION.PREPARE_METADATA:
      return {
        ...state,
        metadata: action.payload,
      };
    default:
      return state;
  }
}

const initialState = {
  emailNotificationList: [],
  tableState: {
    skipPageReset: false,
    toolbarRequired: false,
    globalSearchRequired: true,
    tableInfo: "Loading Table Data",
    requiredColumns: ["email"],
    filteredColumns: [],
    rowsPerPageOptions: {
      pageSize: 10,
      pageSizes: [10],
    },
  },
};

interface ProvisionWarningProps {
  isOpen: boolean;
  data: any;
  toggleModal: any;
}

/**
 * Creates a modal window displaying the data required to provision a virtual machine
 * and show a warning that they are about to provision an expensive machine
 * @param props ProvisionWarningProps
 * @returns A warning modal window
 */
export default function ProvisionWarning(props: ProvisionWarningProps) {
  const { isOpen, data, toggleModal } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { emailData, isLoading } = useGetEmails();
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>();

  const [startingVM, setStartingVM] = useState(false);

  const classes = useStyles();

  const email_data = useMemo(() => emailData, [emailData]);

  useEffect(() => {
    const accountInfo = msalInstance.getActiveAccount();
    if (accountInfo) {
      setAccountInfo(accountInfo);
      dispatch({
        type: ACTION.ADD_EMAIL,
        payload: { email: accountInfo.username.toLowerCase() },
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setStartingVM(false);
    }
  }, [isOpen]);

  function automationLevel(value: string) {
    switch (value) {
      case "Build Model VM and Run":
        return 0;
      case "Build Model VM":
        return 1;
      default:
        break;
    }
  }

  function onModelCompleteToInt(value: string) {
    switch (value) {
      case "Leave VM Running":
        return 0;
      case "Deallocate":
        return 1;
      case "Delete":
        return 2;
      default:
        break;
    }
  }

  const processRequest: any = () => {
    if (accountInfo) {
      let content: any =
        data.automationLevel === "Build Model VM"
          ? {
              gpuCount: data.gpuCount,
              gridSize: data.gridSize,
              simulationHours: data.simulationHours,
              vmSize: data.vmSize,
              catchment: "All",
              automationLevel: automationLevel(data.automationLevel),
              vmStateAfterModelComplete: 0,
              initiatedBy: accountInfo.username,
              emailNotificationList: state.emailNotificationList.join(","),
              deployEnvironment: process.env.REACT_APP_ENVIRONMENT,
            }
          : {
              ...data,
              catchment: data.catchment.replace(/ /g, "_").toLowerCase(),
              automationLevel: automationLevel(data.automationLevel),
              vmStateAfterModelComplete: onModelCompleteToInt(
                data.vmStateAfterModelComplete
              ),
              initiatedBy: accountInfo.username,
              emailNotificationList: state.emailNotificationList.join(","),
              deployEnvironment: process.env.REACT_APP_ENVIRONMENT,
            };

      provisionVM(
        JSON.stringify({
          report: "FEDSS_VMBuild",
          parameters: content,
        })
      )
        .then((status) => {
          if (status === 200 || status === 202) {
            setStartingVM(true);
          } else {
            console.warn("Unkown status: ", status);
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    }

    toggleModal();
  };

  const emailExists = (input: any) => {
    return state.emailNotificationList.includes(input.email);
  };

  const renderSelectCell = ({ row }: { row: Row }) => {
    let found = emailExists(row.original);
    return found ? (
      <Button
        variant="outlined"
        color="primary"
        className={classes.listButton}
        onClick={() =>
          dispatch({ type: ACTION.REMOVE_EMAIL, payload: row.original })
        }
      >
        Remove
      </Button>
    ) : (
      <Button
        variant="contained"
        color="secondary"
        className={classes.listButton}
        onClick={() =>
          dispatch({ type: ACTION.ADD_EMAIL, payload: row.original })
        }
      >
        Add
      </Button>
    );
  };

  const columns: Array<Column> = [
    {
      Header: "Email Recipient",
      accessor: "email",
    },
    {
      Header: () => null,
      id: "selectEmail",
      Cell: ({ row }: { row: any }) => <span>{renderSelectCell({ row })}</span>,
    },
  ];

  return (
    <Paper className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          {startingVM === false ? (
            <Grid className={classes.paper}>
              <Typography variant="h5" className={classes.header}>
                Warning! You are about to provision a very expensive Virtual
                Machine.
              </Typography>
              <Grid className={classes.container}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Paper elevation={1} style={{}}>
                    <Typography className={classes.subHeading} variant="h6">
                      Batch Input Data
                    </Typography>
                    <Grid className={classes.inputData}>
                      <List>
                        <ListItem className={classes.listItem}>
                          <Typography>Automation Level:</Typography>
                          <Typography>{data["automationLevel"]}</Typography>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <Typography>VM Size:</Typography>
                          <Typography>{data["vmSize"]}</Typography>
                        </ListItem>
                        {data["automationLevel"] ===
                          "Build Model VM and Run" && (
                          <>
                            <ListItem className={classes.listItem}>
                              <Typography>GPU Count:</Typography>
                              <Typography>{data["gpuCount"]}</Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Typography>Catchment:</Typography>
                              <Typography>{data["catchment"]}</Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Typography>Simulation Hours:</Typography>
                              <Typography>{data["simulationHours"]}</Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Typography>Grid Size (m):</Typography>
                              <Typography>{data["gridSize"]}</Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Typography>On Model Complete:</Typography>
                              <Typography>
                                {data["vmStateAfterModelComplete"]}
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Typography>CSV History Model ID:</Typography>
                              <Typography>
                                {data["csvHistoryModelId"]}
                              </Typography>
                            </ListItem>
                          </>
                        )}
                      </List>
                    </Grid>
                  </Paper>
                  <Paper style={{ height: "30vh", marginTop: 5 }}>
                    <Typography className={classes.subHeading} variant="h6">
                      Email Recipient List:
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        overflow: "hidden",
                        height: "100%",
                      }}
                    >
                      <List
                        style={{
                          overflow: "auto",
                          width: "100%",
                          marginBottom: 35,
                        }}
                      >
                        {state.emailNotificationList.map((email: string) => {
                          return (
                            <ListItem key={email} className={classes.outputs}>
                              <Typography>{email}</Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  </Paper>
                </div>
                <div style={{ margin: "0 20px", height: "50vh" }}>
                  {!isLoading ? (
                    <DataTable
                      columns={columns}
                      data={email_data}
                      tableState={state.tableState}
                    />
                  ) : (
                    <h3> Loading Email List</h3>
                  )}
                </div>
              </Grid>
              <Grid className={classes.container}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={processRequest}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              className={classes.paper}
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                width: "25vw",
              }}
            >
              <Typography variant="h5" className={classes.header}>
                Your virtual machine is being provisioned
              </Typography>
              <Typography className={classes.header}>
                You will shortly receive a confirmation email. The provisioning
                process will take a few minutes before the virtual machine will
                become available.
              </Typography>
              <Typography className={classes.header}>
                After a short period, you can manually refresh the virtual
                machine table to view any changes.
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={toggleModal}
              >
                OK
              </Button>
            </Grid>
          )}
        </Fade>
      </Modal>
    </Paper>
  );
}
