import axios from 'axios'

/**
 * Lists all stored user emails
 * @returns A promise which eventuated to a list of user emails
 */
export async function getAllEmails(): Promise<Array<{ email: string }>> {
  const key = process.env.REACT_APP_FUNCTION_KEY
  const env = process.env.REACT_APP_ENVIRONMENT
  const func_name = env == "Prod" ? "cogcfedss" : "cogctfedss"
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `https://${func_name}.azurewebsites.net/api/email_query?code=${key}`
    })
      .then(resp => resolve(resp.data.records))
      .catch(e => reject(e))
  })
}

/**
 * Delete a user email from the database
 * @param email The email to be deleted from storage
 * @returns The response from the POST request
 */
export async function deleteEmail(email: string): Promise<number> {
  const key = process.env.REACT_APP_FUNCTION_KEY
  const env = process.env.REACT_APP_ENVIRONMENT
  const func_name = env == "Prod" ? "cogcfedss" : "cogctfedss"
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `https://${func_name}.azurewebsites.net/api/email_delete?code=${key}&email=${email}`
    })
      .then(resp => resolve(resp.status))
      .catch(e => reject(e))
  })
}

/**
 * Adds a single user email to the database
 * @param email The user email to be added to storage
 * @returns The response from the POST request
 */
export async function addEmail(email: string): Promise<number> {
  const key = process.env.REACT_APP_FUNCTION_KEY
  const env = process.env.REACT_APP_ENVIRONMENT
  const func_name = env == "Prod" ? "cogcfedss" : "cogctfedss"
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `https://${func_name}.azurewebsites.net/api/email_create?code=${key}&email=${email}`
    })
      .then(resp => resolve(resp.status))
      .catch(e => reject(e))
  })
}