//#region Imports
import { useMemo, useCallback, useState } from "react";
import { Link, BrowserRouterProps } from "react-router-dom";
import { Row as TRow } from "react-table";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useModelData } from "../../components/hooks/useModelData";
import DataTable from "../../components/data-tables/DataTable";
// import Button from "@material-ui/core/Button";
//#endregion

const initialTableState = {
  skipPageReset: false,
  toolbarRequired: true,
  globalSearchRequired: true,
  tableInfo: "Loading Table Data",
  requiredColumns: [
    "expander",
    "modelId",
    "catchment",
    "modelStartDate",
    "modelStartTime",
    "initiatedBy",
    "viewAnimation",
  ],
  filteredColumns: [
    "gpuCount",
    "modelRuntime",
    "modelFinishDate",
    "modelFinishTime",
    "vmSize",
    "simulationHours",
    "gridSize",
    "storageState",
    "yearMonth",
    "urbsStartDate",
    "urbsStartTime",
    "urbsFore",
    "urbsProbability",
    "deployStartTime",
    "deployStartDate",
  ],
  rowsPerPageOptions: {
    pageSize: 25,
    pageSizes: [25, 50, 100],
  },
};

/** The History page displays all historical models that have been run in a table */
interface ILocationProps extends BrowserRouterProps { }
export default function Index(props: ILocationProps) {
  const [tableState] = useState(initialTableState);
  const { modelData, rehydrate, isLoading } = useModelData();

  /** Creates the row specific link which is used to open a new tab for that flood animation */
  const renderModelAnimation = useCallback(({ row }: { row: TRow }) => {
    let catchment = row.values.catchment.toLowerCase();
    let model_id = row.values.modelId;
    let date = row.values.urbsStartDate.replace(/-/g, "_");
    let time = row.values.urbsStartTime.replace(":", "_");
    return (
      <div>
        <Link to={`/animation/${catchment}/${date}/${time}/${model_id}`}>
          Preview Animation
        </Link>
      </div>
    );
  }, []);

  /** Creates a "More Details" section when the table row is expanded */
  const renderRowSubComponent = useCallback(({ row }: { row: TRow }) => {
    return (
      <Paper style={{ margin: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <List>
              <ListItem>
                <ListItemText
                  primary="GPU Count"
                  secondary={row.values.gpuCount}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Grid Size"
                  secondary={`${row.values.gridSize}m`}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="VM Size" secondary={row.values.vmSize} />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs>
            <List>
              <ListItem>
                <ListItemText
                  primary="URBS Start Date"
                  secondary={row.values.urbsStartDate}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="URBS Start Time"
                  secondary={row.values.urbsStartTime}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Simulation Hours"
                  secondary={row.values.simulationHours}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs>
            <List>
              <ListItem>
                <ListItemText
                  primary="Deploy Start Time"
                  secondary={row.values.deployStartTime}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Model Start Time"
                  secondary={row.values.modelStartTime}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Model Finish Time"
                  secondary={row.values.modelFinishTime}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs>
            <ListItem>
              <ListItemText
                primary="Model Runtime"
                secondary={row.values.modelRuntime}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="URBS FORE"
                secondary={row.values.urbsFore}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="URBS Probabilty"
                secondary={`${row.values.urbsProbability}%`}
              />
            </ListItem>
          </Grid>
        </Grid>
      </Paper>
    );
  }, []);

  /** UseMemo is utilised so the table data does not update on rerender */
  const data = useMemo(() => modelData, [modelData]);

  /** Columns for table */
  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }: { row: TRow }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </span>
        ),
      }, {
        Header: "Model ID",
        accessor: "modelId", // accessor is the "key" in the data
      }, {
        Header: "Catchment",
        accessor: "catchment",
      }, {
        Header: "Start Date",
        accessor: "modelStartDate",
      }, {
        Header: "Start Time",
        accessor: "modelStartTime",
      }, {
        Header: "Gpu Count",
        accessor: "gpuCount",
      }, {
        Header: "Run Time",
        accessor: "modelRuntime",
      }, {
        Header: "VM Size",
        accessor: "vmSize",
      }, {
        Header: "Sim Hours",
        accessor: "simulationHours",
      }, {
        Header: "Grid Size",
        accessor: "gridSize",
      }, {
        Header: "Finish Date",
        accessor: "modelFinishDate",
      }, {
        Header: "Finish Time",
        accessor: "modelFinishTime",
      }, {
        Header: "Initiated By",
        accessor: "initiatedBy",
      }, {
        Header: "Storage State",
        accessor: "storageState",
      }, {
        Header: "Year/Month",
        accessor: "yearMonth",
      }, {
        Header: "URBS Start Date",
        accessor: "urbsStartDate",
      }, {
        Header: "URBS Start Time",
        accessor: "urbsStartTime",
      }, {
        Header: "URBS FORE",
        accessor: "urbsFore",
      }, {
        Header: "URBS Probability",
        accessor: "urbsProbability",
      }, {
        Header: "Deploy Start Time",
        accessor: "deployStartTime",
      }, {
        Header: "Deploy Start Date",
        accessor: "deployStartDate",
      }, {
        Header: () => null,
        id: "viewAnimation",
        Cell: ({ row }: { row: TRow }) => (
          <span>{renderModelAnimation({ row })}</span>
        ),
      },
    ],
    [renderModelAnimation]
  );

  /** The whole page is the container for the table and its tool bar */
  return (
    <Container maxWidth="xl">
      {!isLoading ? (
        <DataTable
          data={data}
          columns={columns}
          tableState={tableState}
          renderRowSubComponent={renderRowSubComponent}
          tableRefresh={rehydrate}
        />
      ) : (
        <Grid style={{ textAlign: "center", marginTop: "20%" }}>
          <h2>Loading Table Data</h2>
        </Grid>
      )}
    </Container>
  );
}
