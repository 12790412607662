
/**
 * Enum list of process types 
 */
export enum ProcessTypes {
    FLOOD_EXTENT = "floodExtent",
    FLOOD_ANIMATION = "floodAnimation",
    FLOODED_PROPERTIES = "floodedProperties",
    FLOODED_ROADS = "floodedRoads",
    ROAD_CLOSURES = "roadClosures"
}