import { changeVirtualMachineState } from "../../../components/api-requests/AzureRequests";

export class VirtualMachines {
  allMachines: Array<VirtualMachine> = [];

  getVirtualMachineByName(name: string): VirtualMachine {
    return this.allMachines.find(
      (machine: VirtualMachine) => machine.name === name
    )!;
  }

  addVirtualMachine(machine: VirtualMachine) {
    this.allMachines = [...this.allMachines, machine];
  }

  removeVirtualMachine(machine: VirtualMachine) {
    this.allMachines = this.allMachines.filter(
      (m: VirtualMachine) => m.name !== machine.name
    );
  }
}

export class VirtualMachine {
  name: string;
  size: string;
  state: string;
  avaialableStates: Array<string>;
  ipAddress: string;
  initiatedBy: string;
  days: number;
  timestamp: string;

  constructor(
    name: string,
    size: string,
    state: string,
    ipAddress: string,
    initiatedBy: string,
    days: number,
    timestamp: string
  ) {
    this.name = name;
    this.size = size;
    this.state = state;
    this.ipAddress = ipAddress;
    this.initiatedBy = initiatedBy;
    this.days = days;
    this.timestamp = timestamp;
    this.avaialableStates = this.setAvailableStates(state);

    console.log(this)
  }

  setAvailableStates(state: string): Array<string> {
    switch (state) {
      case "VM running":
        return ["Deallocate", "Delete"];
      case "VM deallocated":
        return ["Start", "Delete"];
      case "VM stopped":
        return ["Start", "Deallocate"];
      default:
        return ["Unkown State"];
    }
  }

  implementState(requiredState: string, username: string) {
    switch (requiredState) {
      case "Start":
        this.startMachine(username);
        break;
      case "Deallocate":
        this.deallocateMachine(username);
        break;
      case "Delete":
        this.deleteMachine(username);
        break;
      default:
        return ["Unkown State"];
    }
  }

  startMachine(username: string) {
    changeVirtualMachineState("start", this.name, username)
      .then((resp) => {
        console.log("Starting machine response", resp);
        this.state = "Running";
        this.avaialableStates = ["Deallocate", "Delete"];
      })
      .catch((err) =>
        console.warn(`Failed to start machine ${this.name}: ${err}`)
      );
  }

  deleteMachine(username: string) {
    changeVirtualMachineState("delete", this.name, username)
      .then((resp) => {
        console.log("Deleting machine response", resp);
        this.state = "Deleted";
        this.avaialableStates = [];
      })
      .catch((err) =>
        console.warn(`Failed to delete machine ${this.name}: ${err}`)
      );
  }

  deallocateMachine(username: string) {
    changeVirtualMachineState("deallocate", this.name, username)
      .then((resp) => {
        console.log("Deallocating machine response", resp);
        this.state = "VM Deallocated";
        this.avaialableStates = ["Start", "Delete"];
      })
      .catch((err) =>
        console.warn(`Failed to deallocate machine ${this.name}: ${err}`)
      );
  }
}
