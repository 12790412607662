/** reducer actions */
export enum Actions {
  INIT_MAP = "INIT_MAP",
  SELECT_ANIMATION_FRAME = "SELECT_ANIMATION_FRAME",
  SELECTED_FRAME = "SELECTED_FRAME",
  TOGGLE_ANIMATION = "TOGGLE_ANIMATION",
  TOGGLE_LAYER = "TOGGLE_LAYER",
  MAP_LOADED = "MAP_LOADED",
}

/** The action input for the reducer */
export type Action = {
  type: Actions;
  payload?: any;
};