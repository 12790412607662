import React from 'react'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Modal } from '@mui/material';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import { VirtualMachine } from '../classes/VirtualMachine';

/** implements material-ui stlying */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
      margin: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
      width: 175
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: 5,
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2, 4, 3),
    }
  }));

interface DeallocateWarningProps {
  isOpen: boolean
  modalResponse: any
  machine: VirtualMachine | undefined
}

/**
 * Displays a modal as a final warning that they are about to deallocate a virtual machine
 * @param props DeallocateWarningProps
 * @returns 
 */
export default function DeallocateWarning(props: DeallocateWarningProps) {
  const { isOpen, modalResponse, machine } = props
  const classes = useStyles();

  return (
    <Paper className={classes.root} >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={modalResponse}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={isOpen}>
          <Grid className={classes.paper}>
            <div style={{ display: 'flex', textAlign: "center", flexDirection: 'column', margin: 25 }}>
              <Typography style={{ marginBottom: 10 }} variant="h4">Deallocate Virtual Machine</Typography>
              <Typography>You are about to Deallocate a Virtual Machine. This will stop all running process and turn off the machine.</Typography>
              <Typography>Are you sure?</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
              <Button style={{ marginRight: 5, width: 200 }}
                variant="contained"
                color="secondary"
                onClick={() => modalResponse({ deallocateMachine: false })}
              >Cancel</Button>
              <Button style={{ marginRight: 5, width: 200 }}
                variant="contained"
                color="primary"
                onClick={() => modalResponse({ deallocateMachine: true, machine: machine })}
              >Deallocate</Button>
            </div>
          </Grid>
        </Fade>
      </Modal>
    </Paper>
  )
}
