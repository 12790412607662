import {
  Configuration,
  PopupRequest,
  SilentRequest,
} from "@azure/msal-browser";

/**
 * Config object to be passed to MSAL library on creation
 */
export class MSALAuthConfig {
  client_id: string | undefined;
  tenant_id: string | undefined;
  msal_config: Configuration;
  login_request_redirect: PopupRequest;
  login_request_silent: SilentRequest;

  constructor() {
    this.client_id = process.env.REACT_APP_MSAL_CLIENT;
    this.tenant_id = process.env.REACT_APP_TENANT_ID;

    this.msal_config = {
      auth: {
        clientId: this.client_id!,
        authority: `https://login.microsoftonline.com/${this.tenant_id}`,
        redirectUri: "/fedss",
        postLogoutRedirectUri: "/",
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    };

    this.login_request_redirect = {
      scopes: [`${this.client_id}/.default`],
      prompt: "select_account",
    };
    
    this.login_request_silent = {
      scopes: [`${this.client_id}/.default`],
    };
  }
}