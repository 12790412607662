import axios from "axios";
import { FloodExtentMetadata } from "../../pages/overall-floodextent/classes/OverallFloodExtent";

/**
 * API request to get a list of the last completed models to be displayed on the landing page
 * @returns A promise that eventuates into a list of the latest completed models
 */
export async function getLatestFloodExtents(): Promise<
  Array<FloodExtentMetadata>
> {
  const params = {
    queryStr: "latest",
    deployEnvironment: process.env.REACT_APP_ENVIRONMENT,
  };
  const key = process.env.REACT_APP_FUNCTION_KEY
  const url = `${process.env.REACT_APP_HISTORY_ENDPOINT}?payload=${JSON.stringify(params)}&code=${key}`;

  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: url,
      headers: {},
    })
      .then((resp) => {
        if (resp.data.records) {
          const extents = resp.data.records
            .filter((record: any) => record !== null)
            .map((record: any) => {
              return new FloodExtentMetadata(
                record.Model_ID,
                record.Catchment,
                record.URBS_Start_Date,
                record.URBS_Start_Time
              );
            });
          resolve(extents);
        } else {
          resolve([]);
        }
      })
      .catch(reject);
  });
}
