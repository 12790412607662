//#region Imports
import React, { useReducer, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ACTIONS } from "./index.t";
import Provisioning from "./components/Provisioning";
import VMTable from "./components/VirtualMachineTable";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Typography from "@material-ui/core/Typography";
import { listVirtualMachines } from "../../components/api-requests/AzureRequests";
import { BrowserRouterProps } from "react-router-dom";
//#endregion

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      padding: theme.spacing(2),
    },
  })
);

/** Reducer used to update the state */
function reducer(state: any, action: any) {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.VIRTUAL_MACHINES:
      return {
        ...state,
        virtualMachines: payload,
        tableLoading: false,
      };
    case ACTIONS.TABLE_LOADING:
      return {
        ...state,
        tableLoading: payload,
      };
    default:
      return state;
  }
}

/** The main component that houses all other compenents for VM provisioning */
interface ILocationProps extends BrowserRouterProps {}
export default function Index(props: ILocationProps) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, { tableLoading: true });

  useEffect(() => {
    rehydrate();
    dispatch({ type: ACTIONS.TABLE_LOADING, payload: false });
  }, []);

  function rehydrate() {
    dispatch({ type: ACTIONS.TABLE_LOADING, payload: true });
    listVirtualMachines()
      .then((resp) => {
        console.log(resp);
        
        dispatch({ type: ACTIONS.VIRTUAL_MACHINES, payload: resp });
      })
      .catch(console.error);
  }

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item md={3} className={classes.section}>
          <Provisioning />
        </Grid>
        <Grid item md={9} className={classes.section}>
          <Tabs>
            <TabList>
              <Tab>
                <Typography>Virtual Machines</Typography>
              </Tab>
            </TabList>
            <TabPanel>
              <Grid>
                {state.tableLoading ? (
                  <Grid style={{ textAlign: "center", marginTop: "20%" }}>
                    <h2>Loading Table Data</h2>
                  </Grid>
                ) : state.virtualMachines ? (
                  <VMTable data={state.virtualMachines} rehydrate={rehydrate} />
                ) : (
                  <Grid style={{ textAlign: "center", marginTop: "20%" }}>
                    <h2>No Virtual Machines</h2>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>
      </Grid>
    </Container>
  );
}
