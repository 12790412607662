export default class USGSOverlay extends google.maps.OverlayView {
  public bounds: google.maps.LatLngBounds;
  private image: any;
  public div: HTMLDivElement;
  
  private _zIndex: number = 0
  public get zIndex(): number { return this._zIndex }
  public set zIndex(v: number) { this._zIndex = v; }

  constructor(
    bounds: google.maps.LatLngBounds,
    image: string,
  ) {
    super();
    this.bounds = bounds;
    this.image = image;
    this.div = document.createElement("div");
  }

  onAdd() {
    this.div.style.borderStyle = "none";
    this.div.style.borderWidth = "0px";
    this.div.style.position = "absolute";
    // Create the img element and attach it to the div.

    const img = document.createElement("img");
    img.src = this.image;
    img.style.width = "100%";
    img.style.height = "100%";
    img.style.position = "absolute";
    this.div.appendChild(img);
    // Add the element to the "overlayLayer" pane.
    const panes = this.getPanes()!;
    panes.overlayLayer.appendChild(this.div);
  }

  draw() {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    const overlayProjection = this.getProjection();

    // Retrieve the south-west and north-east coordinates of this overlay
    // in LatLngs and convert them to pixel coordinates.
    // We'll use these coordinates to resize the div.
    const sw = overlayProjection.fromLatLngToDivPixel(
      this.bounds.getSouthWest()
    )!;
    const ne = overlayProjection.fromLatLngToDivPixel(
      this.bounds.getNorthEast()
    )!;

    // Resize the image's div to fit the indicated dimensions.
    if (this.div) {
      this.div.style.left = sw.x + "px";
      this.div.style.top = ne.y + "px";
      this.div.style.width = ne.x - sw.x + "px";
      this.div.style.height = sw.y - ne.y + "px";
    }
  }

  /**
   * The onRemove() method will be called automatically from the API if
   * we ever set the overlay's map property to 'null'.
   */
  onRemove() {
    if (this.div) {
      (this.div.parentNode as HTMLElement).removeChild(this.div);
    }
  }

  /**
   *  Hides this div from view
   */
  hide() {
    if (this.div) {
      this.div.style.visibility = "hidden";
    }
  }

  /**
   *  Sets the div to be visible while applying the zindex
   */
  show() {
    if (this.div) {
      this.div.style.zIndex = this.zIndex.toString();
      this.div.style.visibility = "visible";
    }
  }
  
  /**
   * Toggles the layers visibility
   */
  toggle() {
    if (this.div) {
      if (this.div.style.visibility === "hidden") {
        this.show();
      } else {
        this.hide();
      }
    }
  }
}
