//#region Imports
import React, { useMemo, useReducer } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { addEmail, deleteEmail } from '../../../components/api-requests/EmailRequests';
import DataTable from '../../../components/data-tables/DataTable'
import ModalWindow from '../../../components/modals/Modal';
import { useGetEmails } from '../../../components/hooks/useEmailRecipients';
import { Column } from "react-table"
//#endregion

enum ACTION {
  TABLE_DATA,
  TOGGLE_MODAL,
  INPUT,
  DELETE_EMAIL,
  ADD_EMAIL
}

/** implements material-ui stlying */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '25vw',
    },
    paper: {
      padding: theme.spacing(2)
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    grid: {
      padding: theme.spacing(2)
    },
    input: {
      margin: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-evenly'
    },
    inputField: {
      width: 250
    },
    button: {
      margin: theme.spacing(1)
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      width: '30vw',
      margin: theme.spacing(3),
      minWidth: 600,
      height: '80vh'
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    table: {
      margin: theme.spacing(1),
      height: '100%'
    }
  }));

function reducer(state: any, action: any) {
  switch (action.type) {
    case ACTION.TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: action.payload
      }
    case ACTION.INPUT:
      return {
        ...state,
        inputs: {
          [action.payload.id]: action.payload.value
        }
      }
    default:
      return state
  }
}

const initialState = {
  modalOpen: false,
  intputs: {},
  tableState: {
    skipPageReset: false,
    toolbarRequired: false,
    globalSearchRequired: true,
    tableInfo: "Loading Table Data",
    requiredColumns: [
      'email'
    ],
    filteredColumns: [],
    rowsPerPageOptions: {
      pageSize: 10,
      pageSizes: [10]
    }
  }
}

/** 
 * This component houses the modal and the components that interact with it and 
 * will allow the user to CRUD the email recipient list.
 * The users listed in the modal will recieve email updates when a model is completed.
 */
export default function EmailRecipients(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { emailData, isLoading, rehydrate } = useGetEmails()
  const classes = useStyles();

  //#region Modal Controls
  const toggleModal = (): void => {
    dispatch({ type: ACTION.TOGGLE_MODAL, payload: state.modalOpen === true ? false : true })
  }

  const handleSubmit = (): void => {
    toggleModal()
  }

  const handleDeleteEmail = (email: string): void => {
    deleteEmail(email).then(status => {
      if (status === 200) {
        rehydrate()
      }
    })
  }

  const handleAddEmail = (email: string): void => {
    addEmail(email).then(status => {
      if (status === 200) {
        rehydrate()
      }
    })
  }
  //#endregion

  //#region Table metadata
  const columns: Array<Column> = useMemo(() => [
    {
      Header: 'Email Recipient',
      accessor: 'email'
    },
    {
      Header: () => null,
      id: 'deleteEmail',
      Cell: ({ row }: { row: any }) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDeleteEmail(row.original.email as string)}>
          Delete
        </Button>
      )
    }], [handleDeleteEmail])

  //#endregion

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container className={classes.container}>
          <Grid item className={classes.grid}>
            <Typography variant="h6">Email Recipients:</Typography>
            <Typography>Update email recipient list</Typography>
          </Grid>
          <Grid item className={classes.grid}>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModal}>
              Open Table
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <ModalWindow
        styling={classes.modal}
        open={state.modalOpen}
        toggleModal={toggleModal}
        handleSubmit={handleSubmit} >
        <div className={classes.content}>
          <div className={classes.section}>
            <Typography variant="h4">Email Recipients</Typography>
          </div>
          <div className={classes.section}>
            <Grid className={classes.input}>
              <TextField
                className={classes.inputField}
                id="standard-basic"
                label="Enter Email"
                type="email"
                onChange={(e) => dispatch({ type: ACTION.INPUT, payload: { id: 'email', value: e.target.value } })}
              />
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => handleAddEmail(state.inputs.email)}>
                Add Email</Button>
            </Grid>
          </div>
          <div className={classes.section}>
            <div className={classes.table}>
              {!isLoading ?
                <DataTable
                  columns={columns}
                  data={emailData}
                  tableState={state.tableState}
                />
                : <h3>Loading Email List</h3>
              }
            </div>
          </div>
        </div>
      </ModalWindow>
    </Container>
  )
}
