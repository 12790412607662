import { DateTime } from "luxon";
import axios from "axios";
import { parseString } from "xml2js";
import { ProcessTypes } from "../../../components/mapping/LayerTypes.t";
import { overlayExtent, OverlayFrame } from "../../../components/mapping/Shared";
import USGSOverlay from "../../../components/mapping/USGSOverlay";

/**
 * A class for the Flood Animation post processing results
 */
export default class FloodAnimation {
  id: string;
  baseEndpoint: string;
  kmlEndpoint: string;
  kmzEndpoint: string;
  modelId: string;
  catchment: string;
  label: string;
  sasKey: string | undefined;
  animationFrames: Array<OverlayFrame> = [];
  urbsDateTime: DateTime;

  private _animationPosition: number = 0
  public get animationPosition(): number { return this._animationPosition }
  public set animationPosition(v: number) { this._animationPosition = v; }

  private _selected: boolean = true
  public get selected(): boolean { return this._selected }
  public set selected(v: boolean) { this._selected = v; }

  constructor(
    catchment: string,
    modelId: string,
    startTime: string,
    startDate: string
  ) {
    let split_date = startDate.split("_");
    let split_time = startTime.split("_");

    this.id = ProcessTypes.FLOOD_ANIMATION;
    this.catchment = catchment;
    this.modelId = modelId;
    this.sasKey = process.env.REACT_APP_SAS_KEY;
    this.urbsDateTime = DateTime.fromObject({
      year: parseInt(split_date[2]),
      month: parseInt(split_date[1]),
      day: parseInt(split_date[0]),
      hour: parseInt(split_time[0]),
      minute: parseInt(split_time[1]),
    });
    this.label = "Flood Animation";
    this.baseEndpoint = process.env.REACT_APP_MODEL_RESULTS_ENDPOINT!;
    this.kmzEndpoint = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_animation/flood_animation.kmz?${this.sasKey}`;
    this.kmlEndpoint = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_animation/flood_animation.kml?${this.sasKey}`;
  }

  /**
   * Parese the contents of the KML file into an object
   * @returns A promise that eventuates into a list of animation layers
   */
  public parseKml(): Promise<Array<any>> {
    return new Promise((res, rej) => {
      axios({
        method: "GET",
        url: this.kmlEndpoint,
        headers: {},
      })
        .then((resp) =>
          parseString(resp.data, (err, data) => {
            if (err) {
              rej(err);
            }
            let { GroundOverlay } = data.kml.Document[0];
            res(
              GroundOverlay.sort((a: any, b: any) =>
                a.name[0].localeCompare(b.name[0])
              )
            );
          })
        )
        .catch((err) => rej(err));
    });
  }

  /**
   * Generates the required data to apply an image as an overlay to a google map
   * @returns A promise that eventuates into a list of overlay frames
   */
  public generateOverlays(): Promise<Array<OverlayFrame>> {
    return new Promise((res: any, rej: any) => {
      this.parseKml().then((data: any) => {
        try {
          res(
            data.map((overlay: any, index: number) => {
              let extent = overlayExtent(
                overlay["gx:LatLonQuad"][0].coordinates[0]
              );
              let bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(extent.swLat, extent.swLng),
                new google.maps.LatLng(extent.neLat, extent.neLng)
              );
              let image_url = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_animation/files/${overlay.name[0].split(".")[0]}.png?${this.sasKey}`;

              let frame: OverlayFrame = {
                name: overlay.name[0],
                overlay: new USGSOverlay(bounds, image_url),
                mapBounds: bounds,
                position: index,
                start: this.urbsDateTime.plus({ hours: index }).toFormat("f"),
                end: this.urbsDateTime.plus({ hours: index + 1 }).toFormat("f"),
                url: image_url,
              };
              return frame;
            })
          );
        } catch (error) {
          rej(error);
        }
      });
    });
  }
}
