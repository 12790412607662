import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Row as TRow } from "react-table"
import Button from '@material-ui/core/Button';
import DataTable from '../../../components/data-tables/DataTable'
import { VirtualMachines, VirtualMachine } from '../classes/VirtualMachine'
import DeleteWarning from './DeleteWarning'
import DeallocateWarning from './DeallocateWarning'
import { msalInstance } from '../../../index'
import { AccountInfo } from "@azure/msal-browser";

const initialState = {
  tableState: {
    skipPageReset: false,
    toolbarRequired: true,
    globalSearchRequired: true,
    tableInfo: "Loading Table Data",
    requiredColumns: [
      'name',
      'ipAddress',
      'state',
      'initiatedBy',
      'days',
      'managment',
      'stateChange'
    ],
    filteredColumns: [
      'timestamp',
      'size'
    ],
    rowsPerPageOptions: {
      pageSize: 25,
      pageSizes: [10, 25, 50]
    }
  }
}

type TableData = {
  data: VirtualMachines,
  rehydrate: Function
}

type DefaultState = {
  isOpen: boolean,
  machine: VirtualMachine | undefined
  username: string | undefined
}

const defaultState: DefaultState = {
  isOpen: false,
  machine: undefined,
  username: undefined
}

/**
 * Displays a table showing the available virtual machines, their current state and the tools
 * to be able to start, deallocate and delete selected machines
 * @param props TableData
 * @returns A table displaying the currently available virtual machines
 */
export default function VMTable(props: TableData) {
  const [deleteModal, setDeleteModal] = useState(defaultState)
  const [deallocateModal, setDeallocateModal] = useState(defaultState)
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>()
  const { rehydrate, data } = props

  useEffect(() => {
    const accountInfo = msalInstance.getActiveAccount()
    setAccountInfo(accountInfo)
  }, [])


  function handleClick(vm: VirtualMachine, desiredState: string) {
    if (accountInfo) {
      if (desiredState === 'Start') {
        vm.startMachine(accountInfo.username)
      }

      if (desiredState === 'Delete') {
        setDeleteModal({ ...deleteModal, machine: vm, isOpen: true })
      }

      if (desiredState === 'Deallocate') {
        setDeallocateModal({ ...deallocateModal, machine: vm, isOpen: true })
      }
    }
  };

  const deleteModalResponse = ({ deleteMachine, machine }: { deleteMachine: boolean, machine: VirtualMachine }) => {
    setDeleteModal({ ...deleteModal, isOpen: false })
    if (deleteMachine && accountInfo) {
      machine.deleteMachine(accountInfo.username)
      data.removeVirtualMachine(machine)
    }
  }

  const deallocateModalResponse = ({ deallocateMachine, machine }: { deallocateMachine: boolean, machine: VirtualMachine }) => {
    setDeallocateModal({ ...deallocateModal, isOpen: false })
    if (deallocateMachine && accountInfo) {
      machine.deallocateMachine(accountInfo.username)
    }
  }

  const renderProvisionCell = useCallback(
    ({ row }: { row: TRow }) => {
      let vm = row.original as VirtualMachine
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {vm.avaialableStates.map((state: string) => {
            return (
              <Button
                key={`${vm.name}_${state}`}
                style={{ marginRight: 5 }}
                variant="contained"
                color="primary"
                onClick={() => handleClick(vm, state)}>
                {state}
              </Button>
            )
          })}
        </div>
      )
    },
    [handleClick]
  )

  const columns: any = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name'
    }, {
      Header: 'IP Address',
      accessor: 'ipAddress'
    }, {
      Header: 'VM State',
      accessor: 'state'
    }, {
      Header: 'VM Size',
      accessor: 'size'
    }, {
      Header: 'Initiated By',
      accessor: 'initiatedBy'
    }, {
      Header: 'Days',
      accessor: 'days'
    }, {
      Header: 'Creation Time',
      accessor: 'timestamp'
    },
    {
      Header: () => null,
      id: 'stateChange',
      Cell: ({ row }: { row: any }) => (
        <span>
          {renderProvisionCell({ row })}
        </span>
      )
    }
  ], [renderProvisionCell])

  return (
    <div>
      <DataTable
        columns={columns}
        data={data.allMachines}
        tableState={initialState.tableState}
        tableRefresh={rehydrate}
      />
      <DeleteWarning
        isOpen={deleteModal.isOpen}
        machine={deleteModal.machine}
        modalResponse={deleteModalResponse}
      />
      <DeallocateWarning
        isOpen={deallocateModal.isOpen}
        machine={deallocateModal.machine}
        modalResponse={deallocateModalResponse}
      />
    </div>
  )
}
