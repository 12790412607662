import { ProcessTypes } from "../../../components/mapping/LayerTypes.t";
import USGSOverlay from "../../../components/mapping/USGSOverlay";
import axios from "axios";
import { parseString } from "xml2js";
import { overlayExtent, OverlayFrame } from "../../../components/mapping/Shared";

/**
 * A class for the Flood Extetn post processing results
 */
export default class FloodExtent {
  id: string;
  baseEndpoint: string;
  kmlEndpoint: string;
  kmzEndpoint: string;
  modelId: string;
  catchment: string;
  label: string;
  sasKey: string | undefined;
  extentFrame: OverlayFrame | undefined;

  constructor(
    catchment: string,
    modelId: string,
  ) {
    this.id = ProcessTypes.FLOOD_EXTENT;
    this.catchment = catchment;
    this.modelId = modelId;
    this.sasKey = process.env.REACT_APP_SAS_KEY;
    this.label = "Flood Extent";
    this.baseEndpoint = process.env.REACT_APP_MODEL_RESULTS_ENDPOINT!;
    this.kmzEndpoint = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_extent/flood_extent.kmz?${this.sasKey}`;
    this.kmlEndpoint = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_extent/flood_extent.kml?${this.sasKey}`;
  }

  /**
   * Generates the required data to apply an image as an overlay to a google map
   */
  public async generateOverlay() {
    axios({
      method: "GET",
      url: this.kmlEndpoint,
      headers: {},
    })
      .then((resp) =>
        // Parses the KML file content into a useable javascript object
        parseString(resp.data, (err, data) => {
          let overlay = data.kml.Document[0].GroundOverlay[0];
          let extent = overlayExtent(
            overlay["gx:LatLonQuad"][0].coordinates[0]
          );
          let image_url = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_extent/files/flood_extent.png?${this.sasKey}`;
          let usgs_overlay = new USGSOverlay(
            new google.maps.LatLngBounds(
              new google.maps.LatLng(
                extent.swLat,
                extent.swLng
              ),
              new google.maps.LatLng(
                extent.neLat,
                extent.neLng
              )
            ),
            image_url
          );
          let map_bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(extent.swLat, extent.swLng),
            new google.maps.LatLng(extent.neLat, extent.neLng)
          );
          var extentFrame = {
            name: overlay.name[0],
            overlay: usgs_overlay,
            mapBounds: map_bounds,
            url: image_url,
          };
          this.extentFrame = extentFrame;
        })
      )
      .catch(console.log);
  }
}
