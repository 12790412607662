import { ProcessTypes } from "../../../components/mapping/LayerTypes.t";
import axios from "axios";
import { parseString } from "xml2js";
import { overlayExtent, OverlayFrame } from "../../../components/mapping/Shared";
import USGSOverlay from "../../../components/mapping/USGSOverlay";
import { DateTime } from "luxon";

export default class OverallFloodExtent {
  id: string;
  baseEndpoint: string;
  kmlEndpoint: string;
  kmzEndpoint: string;
  modelId: string;
  catchment: string;
  label: string;
  sasKey: string | undefined;
  layer: google.maps.KmlLayer | undefined;
  extentFrame: OverlayFrame | undefined;
  animationUrl: string

  constructor(catchment: string, modelId: string, animationUrl: string) {
    const endpoint = process.env.REACT_APP_MODEL_RESULTS_ENDPOINT
    this.id = ProcessTypes.FLOOD_EXTENT;
    this.catchment = catchment;
    this.modelId = modelId;
    this.sasKey = process.env.REACT_APP_SAS_KEY;
    this.label = "Flood Extent";
    this.baseEndpoint = endpoint!;
    this.kmzEndpoint = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_extent/flood_extent.kmz?${this.sasKey}`;
    this.kmlEndpoint = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_extent/flood_extent.kml?${this.sasKey}`;
    this.animationUrl = animationUrl
  }

  public async generateOverlay(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: this.kmlEndpoint,
        headers: {},
      })
        .then((resp) =>
          parseString(resp.data, (err, data) => {
            if (err) {
              reject(err);
            }
            let overlay = data.kml.Document[0].GroundOverlay[0];
            let extent = overlayExtent(
              overlay["gx:LatLonQuad"][0].coordinates[0]
            );
            let image_url = `${this.baseEndpoint}/${this.catchment}/${this.modelId}/flood_extent/files/flood_extent.png?${this.sasKey}`;
            let usgs_overlay = new USGSOverlay(
              new google.maps.LatLngBounds(
                new google.maps.LatLng(
                  extent.swLat,
                  extent.swLng
                ),
                new google.maps.LatLng(
                  extent.neLat,
                  extent.neLng
                )
              ),
              image_url
            );
            resolve({
              catchment: this.catchment,
              modelId: this.modelId,
              overlay: usgs_overlay,
              url: image_url,
              animationUrl: this.animationUrl
            });
          })
        )
        .catch(reject);
    });
  }
}

export class FloodExtentMetadata {
  modelId: string;
  catchment: string;
  urbsStartDate: string;
  urbsStartTime: string;
  animationUrl: string

  constructor(
    modelId: string,
    catchment: string,
    urbsStartDate: string,
    urbsStartTime: string
  ) {
    this.modelId = modelId;
    this.catchment = catchment;

    if (urbsStartDate) {
      this.urbsStartDate = urbsStartDate.split('-').join('_')
    } else {
      this.urbsStartDate = "00_00_0000"
    }

    if (urbsStartTime) {
      let split_time = urbsStartTime.split(':');
      this.urbsStartTime = `${split_time[0]}_${split_time[1]}`
    } else {
      this.urbsStartTime = "00_00"
    }

    this.animationUrl = `/animation/${this.catchment}/${this.urbsStartDate}/${this.urbsStartTime}/${this.modelId}`
  }
}
