import React, { useEffect, useState } from "react";
import Navbar from "./components/navbar/Index";
import Viewer from "./pages/floodmap/Index";
import History from "./pages/history/Index";
import Modelling from "./pages/modelling/Index";
import Help from "./pages/help/Index";
import GlobalConfig from "./pages/global-config/Index";
import OverallFloodExtent from "./pages/overall-floodextent/Index";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { MSALAuthConfig } from "./components/authentication/authConfig";
import { msalInstance } from ".";
import { Route, Routes, BrowserRouterProps, Navigate } from "react-router-dom";

/** Overrides the default theme colours */
const theme = createTheme({
  palette: {
    primary: {
      main: "#218FA3",
    },
    secondary: {
      main: "#FFF",
      contrastText: "#218FA3",
    },
  },
});

interface ILocationProps extends BrowserRouterProps {
  msal_auth: MSALAuthConfig;
}

function App(props: ILocationProps) {
  const [auth, setAuth] = useState<null | any>(null);
  const msal_auth = props.msal_auth;

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        const account = msalInstance.getActiveAccount();
        if (!account) {
          // If no authenticated user, redirect to login page
          msalInstance.loginRedirect(msal_auth.login_request_redirect);
        }
        if (account) {
          // Aquire the users token for Bearer token usage
          msalInstance
            .acquireTokenSilent(msal_auth.login_request_silent)
            .then((tokenResponse) => setAuth(tokenResponse))
            .catch((err) => {
              msalInstance.loginRedirect(msal_auth.login_request_redirect);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [msal_auth]);

  return auth !== null ? (
    <ThemeProvider theme={theme}>
      <Navbar path="/">
        <Routes>
          <Route path="/" element={<OverallFloodExtent />} />
          <Route path="modelling" element={<Modelling />} />
          <Route path="history" element={<History />} />
          <Route path="global-config" element={<GlobalConfig />} />
          <Route path="help" element={<Help />} />
          <Route
            path="animation/:catchment/:start_date/:start_time/:model_id"
            element={<Viewer />}
          />
          <Route path="*" element={<Navigate to ="/" />} />
        </Routes>
      </Navbar>
    </ThemeProvider>
  ) : (
    <div>Authenticating. You are being redirected to the login page.</div>
  );
}

export default App;
