//#region Imports
import React, { useReducer, useEffect, useMemo } from "react";
import { BrowserRouterProps, Link } from "react-router-dom";
import { Actions, Action } from "./Index.t";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { getLatestFloodExtents } from "../../components/api-requests/SpatialDataRequests";
import OverallFloodExtent, {
  FloodExtentMetadata,
} from "./classes/OverallFloodExtent";
import USGSOverlay from "../../components/mapping/USGSOverlay";

/** Implements material-ui styling */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 10,
    },
    details: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    controls: {
      alignItems: "center",
    },
    playIcon: {
      height: 45,
      width: 45,
    },
  })
);

/** The main reducer to manage the pages state */
function reducer(state: any, action: Action) {
  const { payload } = action;
  switch (action.type) {
    case Actions.INIT:
      return {
        ...state,
        ...payload,
      };
    case Actions.ADD_LAYER:
      return {
        ...state,
        layers: {
          ...state.layers,
          [payload.id]: payload.layer,
        },
      };
    default:
      return state;
  }
}

/** Initial state values for the page */
const initialState = {
  mapConfig: {
    zoom: 10,
    mapCenter: {
      lat: -27.989,
      lng: 153.336,
    },
  },
};

/**
 *
 */
interface ILocationProps extends BrowserRouterProps { }
export default function Index(props: ILocationProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  /** SAS key to allow access to the storage account */
  const sas_key = useMemo(() => process.env.REACT_APP_SAS_KEY, []);

  const classes = useStyles();
  /**
 * Inits the google map
 */
  useEffect(() => {
    let map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: state.mapConfig.zoom,
        center: state.mapConfig.mapCenter,
        mapTypeId: "satellite",
      }
    );
    dispatch({ type: Actions.INIT, payload: { map: map } });
  }, [state.mapConfig.zoom, state.mapConfig.mapCenter]);

  /**
   * When the map is set, collect the overlay data and set all flood extents to the map
   */
  useEffect(() => {
    if (sas_key) {
      getLatestFloodExtents().then((records) => {
        records.forEach((record: FloodExtentMetadata) => {
          let flood_extent = new OverallFloodExtent(
            record.catchment,
            record.modelId,
            record.animationUrl
          );
          flood_extent
            .generateOverlay()
            .then((resp) => {
              let overlay = resp.overlay as USGSOverlay;
              overlay.setMap(state.map);
              dispatch({
                type: Actions.ADD_LAYER,
                payload: { id: resp.catchment, layer: resp },
              });
            })
            .catch(console.error);
        });
      });
    }
  }, [state.map, sas_key]);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <div id="map" style={{ width: "100%", height: "85vh" }}></div>
        </Grid>
        {state.layers && (
          <Grid item xs={2}>
            <Card className={classes.root}>
              <CardContent className={classes.content}>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  Catchments
                </Typography>
                <Divider style={{ margin: 10 }} />
                {Object.keys(state.layers).map((key: string) => {
                  return (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                      key={key}
                    >
                      <Link to={state.layers[key].animationUrl}>
                        <Typography>{state.layers[key].catchment}</Typography>
                        <Typography>{state.layers[key].modelId}</Typography>
                      </Link>
                      <Divider style={{ margin: 10 }} />
                    </div>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
