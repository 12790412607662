//#region Imports
import React, { useState, useRef, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
// import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@mui/material';
//#endregion

/** All material-ui styles for this component */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    popper: {
      display: 'flex',
      justifyContent: 'center'
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end'

    },
    button: {
      margin: theme.spacing(1),
    }
  }),
);

/** The toolbar houses the components that interact with the table */
export default function Toolbar(props: any) {
  const { tableRefresh, requiredColumns, allColumns } = props
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  //#region Implementations for column filter options
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  //#endregion

  return (
    <Grid container style={{ justifyContent: 'flex-start' }}>
      <Grid item xs={10} className={classes.buttons} style={{ justifyContent: 'flex-start', marginLeft: 20 }} >
        <Button
          ref={anchorRef}
          color="secondary"
          variant="contained"
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.button}
        >
          Filter Columns
        </Button>
        {/** Dropdown for column filter options */}
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.popper}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper style={{ zIndex: 100 }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    <FormGroup style={{ margin: '0px 20px' }}>
                      {
                        allColumns.map((column: any) => {
                          return !requiredColumns.includes(column.id)
                            ? <FormControlLabel
                              control={<Checkbox
                                {...column.getToggleHiddenProps()} />}
                              label={column.Header} />
                            : <></>
                        })
                      }
                    </FormGroup>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {/** This button forces the table to refresh its data */}
        <Button className={classes.button} variant="contained" color="primary" onClick={() => tableRefresh()} >Refresh Table</Button>
      </Grid>
    </Grid>
  )
}
