import React from 'react'
import Container from '@material-ui/core/Container'
import { BrowserRouterProps } from "react-router-dom";


/** Basic help page to help users understand how the app works */
interface ILocationProps extends BrowserRouterProps { }
export default function index(props: ILocationProps) {
    return (
        <div>
            <Container>
                <h1>Help!</h1>
                <p>
                    This page will give an overview of what is in the app and how to use it. Due to there being no animation selected there would
                    be no point displaying the map.
                </p>
                <p>From here the user can go to the Modelling page to start a new model or visit the history page and select a completed model.</p>
                <ul style={{ listStyle: 'none' }}>
                    <li style={{ textAlign: 'left' }}>
                        <h2>Modelling:</h2>
                        <p>
                            The modelling page is where the start of the modelling process begins. The user can provision virtual machines and enter
                            the metadata required for the model to be run.
                        </p>
                    </li>
                    <li style={{ textAlign: 'left' }}>
                        <h2>History:</h2>
                        <p>
                            The history page is where all historical models and their metadata can be viewed. It is from here where you can select
                            if you want to view the animation for that flood model.
                        </p>
                    </li>
                    <li style={{ textAlign: 'left' }}>
                        <h2>Flood Animation:</h2>
                        <p>The Flood Animation page is accessible by selecting the required model from the History page</p>
                    </li>
                </ul>
            </Container>
        </div>
    )
}
