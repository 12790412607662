import { useState, useEffect } from "react";
import { useAxiosGet } from "./useAxios";
import Entity from "../../pages/history/classes/Model";

/**
 * API request hook to collect the list of all historical model data to be displayed on the history table
 * @returns All data, the loading state, any errors and the ability to recall the api
 */
export function useModelData(): {
  modelData: Array<Entity>;
  rehydrate: Function;
  isLoading: boolean;
  error: string | null;
} {
  const [modelData, setModelData] = useState<Array<Entity>>([]);
  const endpoint = process.env.REACT_APP_HISTORY_ENDPOINT
  const key = process.env.REACT_APP_FUNCTION_KEY
  const params = {
    deployEnvironment: process.env.REACT_APP_ENVIRONMENT 
  };
  const url = `${endpoint}?payload=${JSON.stringify(params)}&code=${key}`;
  const { data, isLoading, recall, error } = useAxiosGet(url);

  useEffect(() => {
    if (data) {
      setModelData(
        data
          .filter((record: any) => record !== null)
          .map((record: any) => {
            return new Entity(record);
          }, [])
      );
    }
  }, [data]);
  return {
    modelData: modelData,
    rehydrate: recall,
    isLoading: isLoading,
    error: error,
  };
}
