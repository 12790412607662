import { useState, useEffect } from "react";
import axios from "axios";

/**
 * General API request hook
 * @param url API where data is returned
 * @returns The data, loading state, any errors and the ability to recall the API
 */
export const useAxiosGet = (url: string) => {
  const [data, setData] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    axios({
      method: "GET",
      url: url,
    })
      .then((resp) => {
        if (resp.data.length != 0) {
          setData(resp.data.records);
          setIsLoading(false);
          setError(null);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  }, [url]);

  const recall = () => {
    setIsLoading(true);
    axios({
      method: "GET",
      url: url,
    })
      .then((resp) => {
        if (resp.data.length != 0) {
          setData(resp.data.records);
          setIsLoading(false);
          setError(null);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return { data, isLoading, recall, error };
};