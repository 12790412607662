import React from 'react'
import EmailRecipients from '../global-config/components/EmailRecipients'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '25vw'
    }
  }));

/**
 * This page will allow the use to alter the configuration for some of the tools implemented in that app.
 * @param props any
 * @returns All configuration option components
 */
export default function Index(props: any) {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <EmailRecipients />
    </Container>
  )
}
