/** reducer actions */
export enum Actions {
    INIT = "INIT",
    DATETIME_RANGE = "DATETIME_RANGE",
    ADD_LAYER = "ADD_LAYER",
    TOGGLE_ANIMATION = "TOGGLE_ANIMATION",
    SET_EXTENT_FRAME = "SET_EXTENT_FRAME",
    TOGGLE_LAYER = "TOGGLE_LAYER",
    SET_ANIMATION_FRAME = "SET_ANIMATION_FRAME",
    ADD_ANIMATION_LAYER = "ADD_ANIMATION_LAYER",
    ADD_EXTENT_LAYER = "ADD_EXTENT_LAYER",
    SLIDER = "SLIDER"
}

/** The action input for the reducer */
export type Action = {
    type: Actions,
    payload?: any
}